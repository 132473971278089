<template>
  <div class="d-flex justify-content-between new-block">
    <div>
      <h2 class="float-start">
        {{
          $t(
            "KanbanView.NewKanban",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </h2>
    </div>
    <div>
      <ActionButtons
        @methodSave="onSubmit('0', 'btn-save')"
        @methodSaveAndNew="onSubmit('1', 'btn-save-and-new')"
        @clear="onClear()"
        :isNewRecord="true"
      />
    </div>
  </div>
  <form class="form-kanban-new pe-2 ps-2" novalidate>
    <div v-if="errors.length > 0" class="alert alert-warning">
      <ul class="mb-0">
        <li v-for="error in errors" v-bind:key="error">
          {{ error }}
        </li>
      </ul>
    </div>
    <div v-if="isSuccess" class="alert alert-success">
      {{
        $t(
          "Messages.SuccessMessage",
          {},
          { locale: this.$store.state.activeLang }
        )
      }}
    </div>
    <div class="row row-cols-1 mt-3">
      <div class="col col-md-6">
        <label for="Name" class="form-label required"
          >{{
            $t("KanbanView.Name", {}, { locale: this.$store.state.activeLang })
          }}
        </label>
        <input
          type="text"
          class="form-control"
          id="Name"
          v-model="kanbanData.name"
          :autocomplete="this.$isAutoComplete"
          :spellcheck="this.$isTextSpellCheck"
          required
        />
      </div>
      <div class="col col-md-6">
        <label for="Description" class="form-label required">{{
          $t(
            "KanbanView.Description",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithRemoteDataAndInternalSearch"
          @onChange="onChangeDescription"
          :isDetailIconShow="true"
          :routeLink="
            String.format(
              '#/CustomObject/EditField/{0}&fieldId=',
              this.$route.params.customObjectId
            )
          "
          :allowEmpty="false"
          :isParameters="true"
          :isGatewayRequest="true"
          :requestUrl="
            String.format(
              '/Lcdp-SummaryFields?coId={0}',
              this.$route.params.customObjectId
            )
          "
        />
      </div>
    </div>
    <div class="row row-cols-1 mt-3">
      <div class="col col-md-6">
        <label for="KanbanColumnField" class="form-label required">{{
          $t(
            "KanbanView.KanbanColumnField",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithRemoteDataAndInternalSearch"
          @onChange="onChangeKanbanColumnField"
          :isDetailIconShow="true"
          :routeLink="
            String.format(
              '#/CustomObject/EditField/{0}&fieldId=',
              this.$route.params.customObjectId
            )
          "
          :allowEmpty="false"
          :isParameters="true"
          :isGatewayRequest="true"
          :requestUrl="
            String.format(
              '/Lcdp-SummaryKanbanViewableFields?coId={0}',
              this.$route.params.customObjectId
            )
          "
        />
      </div>
      <div class="col col-md-6">
        <label for="HeaderField" class="form-label required">{{
          $t(
            "KanbanView.HeaderField",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithRemoteDataAndInternalSearch"
          @onChange="onChangeHeaderField"
          :isDetailIconShow="true"
          :routeLink="
            String.format(
              '#/CustomObject/EditField/{0}&fieldId=',
              this.$route.params.customObjectId
            )
          "
          :allowEmpty="false"
          :isParameters="true"
          :isGatewayRequest="true"
          :requestUrl="
            String.format(
              '/Lcdp-SummaryFields?coId={0}',
              this.$route.params.customObjectId
            )
          "
        />
      </div>
    </div>
    <div class="row row-cols-1 mt-3">
      <div class="col col-md-6">
        <label for="PageLayout" class="form-label required">{{
          $t(
            "KanbanView.PageLayout",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithRemoteDataAndInternalSearch"
          @onChange="onChangePageLayout"
          :isDetailIconShow="true"
          :routeLink="
            String.format(
              '#/CustomObject/EditPageLayout/{0}&pageLayoutId=',
              this.$route.params.customObjectId
            )
          "
          :allowEmpty="false"
          :isParameters="true"
          :isGatewayRequest="true"
          :requestUrl="
            String.format(
              '/Lcdp-SummaryPageLayouts?coId={0}',
              this.$route.params.customObjectId
            )
          "
        />
      </div>
      <div class="col col-md-6">
        <label for="CalculatedField" class="form-label required">{{
          $t(
            "KanbanView.CalculatedField",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithRemoteDataAndInternalSearch"
          @onChange="onChangeCalculatedField"
          :isDetailIconShow="true"
          :routeLink="
            String.format(
              '#/CustomObject/EditField/{0}&fieldId=',
              this.$route.params.customObjectId
            )
          "
          :allowEmpty="false"
          :isParameters="true"
          :isGatewayRequest="true"
          :requestUrl="
            String.format(
              '/Lcdp-SummaryFields?coId={0}',
              this.$route.params.customObjectId
            )
          "
        />
      </div>
    </div>
    <div class="row row-cols-1 mt-3 mb-3">
      <div class="col col-md-4">
        <label for="CalculatedFieldSuffix" class="form-label"
          >{{
            $t(
              "KanbanView.CalculatedFieldSuffix",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </label>
        <input
          type="text"
          class="form-control"
          id="CalculatedFieldSuffix"
          v-model="kanbanData.calculatedFieldSuffix"
          :autocomplete="this.$isAutoComplete"
        />
      </div>
      <div class="col col-md-4">
        <label for="AggregateFunction" class="form-label">{{
          $t(
            "KanbanView.AggregateFunction",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithLocalData"
          :selectedData="selectedAggregateFunction"
          :allowEmpty="false"
          :data="aggregateFunctionData"
          @onChange="onChangeForAggregateFunction"
        />
      </div>
      <div class="col col-md-4">
        <label for="CountSuffix" class="form-label"
          >{{
            $t(
              "KanbanView.CountSuffix",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </label>
        <input
          type="text"
          class="form-control"
          id="CountSuffix"
          v-model="kanbanData.countSuffix"
          :autocomplete="this.$isAutoComplete"
        />
      </div>
    </div>
    <ActionButtons
      v-if="$isMobile()"
      @methodSave="onSubmit('0', 'btn-save')"
      @methodSaveAndNew="onSubmit('1', 'btn-save-and-new')"
      @clear="onClear()"
      :isNewRecord="true"
    />
  </form>
</template>
<script>
import $ from "jquery";
export default {
  name: "CustomObjectNewKanban",
  components: {},
  data() {
    return {
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save, .btn-save-and-new, .btn-clear",
      errors: [],
      kanbanData: {
        isActive: true,
        customObjectPublicId: this.$route.params.customObjectId,
        aggregateFunctionId: 1,
      },
      isSuccess: false,
      aggregateFunctionData: [
        {
          key: "1",
          value: this.$t(
            "KanbanView.AggregateSum",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "2",
          value: this.$t(
            "KanbanView.AggregateAverage",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "3",
          value: this.$t(
            "KanbanView.AggregateCount",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "4",
          value: this.$t(
            "KanbanView.AggregateMax",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "5",
          value: this.$t(
            "KanbanView.AggregateMin",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      selectedAggregateFunction: [
        {
          key: "1",
          value: this.$t(
            "KanbanView.AggregateSum",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      selectValueReset: false,
      nullObject: [{ key: null, value: null }],
    };
  },
  methods: {
    onChangeDescription: function (selected) {
      this.kanbanData.descriptionFieldPublicId = selected.key;
    },
    onChangeKanbanColumnField: function (selected) {
      this.kanbanData.kanbanColumnFieldPublicId = selected.key;
    },
    onChangeHeaderField: function (selected) {
      this.kanbanData.headerFieldPublicId = selected.key;
    },
    onChangePageLayout: function (selected) {
      this.kanbanData.pageDesignerPublicId = selected.key;
    },
    onChangeCalculatedField: function (selected) {
      this.kanbanData.calculatedFieldPublicId = selected.key;
    },
    onChangeForAggregateFunction: function (selected) {
      this.kanbanData.aggregateFunctionId = selected.key;
    },
    onClear() {
      this.kanbanData = {
        isActive: true,
        customObjectPublicId: this.$route.params.customObjectId,
        aggregateFunctionId: 1,
      };
      $(".form-kanban-new").removeClass("was-validated");
    },
    onSubmit(submitType, buttonName) {
      var form = $(".form-kanban-new");
      form.addClass("was-validated");
      if (
        !form[0].checkValidity() ||
        form.find(".ms-invalid:visible").length > 0
      ) {
        return;
      }

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.errors = [];
      this.$prodGatewayAxios
        .post("/Lcdp-KanbanViewNew", { ...this.kanbanData })
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            if (submitType == 0) {
              this.$router.push(
                "/CustomObject/Kanban/" + this.$route.params.customObjectId
              );
            } else {
              this.isSuccess = true;
              setTimeout(
                () => (this.isSuccess = false),
                this.$appSaveAndNewMessageDuration
              );
              this.onClear();
              firstSpan.show();
              loadingBlock.hide();
              disabledButtons.prop("disabled", false);
              form.removeClass("was-validated");
            }
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          this.errors.push(r);
        });
    },
  },
  watch: {
    "$route.params.customObjectId"() {
      this.kanbanData.customObjectPublicId = this.$route.params.customObjectId;
    },
  },
};
</script>
